import { GET_ERRORS, CLEAR_ERRORS, RESET_ALL } from "../config/actionTypes";
const initialState = {};
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ERRORS:
      return action.payload;

    case CLEAR_ERRORS:
      return initialState;

    case RESET_ALL:
      return initialState;
    default:
      return state;
  }
}
