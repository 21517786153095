import api from "../utils/api";
import apiUrls from "../config/apiUrls";

import {
  SEND_CONNECT,
  OPEN_CONNECT,
  CLOSE_CONNECT,
  GET_SENT_MESSAGES,
  GET_INBOX_MESSAGES,
  RESET_CONNECT,
} from "../config/actionTypes";
import { setMessage, setLoading } from "./generalActions";

export const getConnectUsers = async (params) => {
  try {
    const res = await api.get(apiUrls.getConnectUsers, { params });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const sendConnect = (data) => async (dispatch) => {
  try {
    const res = await api.post(apiUrls.sendConnect, data);

    if (res.status === 200) {
      dispatch({ type: SEND_CONNECT });
      dispatch(toggleConnect(false));
      dispatch(setMessage("Message sent."));
    }
  } catch (error) {}
};

export const toggleConnect = (isOpen) => (dispatch) => {
  if (isOpen) dispatch({ type: OPEN_CONNECT });
  else dispatch({ type: CLOSE_CONNECT });
};

export const getSendMessages = (params) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await api.get(apiUrls.getSendMessages, { params });

    if (res.status === 200) {
      dispatch({
        type: GET_SENT_MESSAGES,
        payload: res.data,
      });
    }
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const getInboxMessages = (params) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await api.get(apiUrls.getInbox, { params });

    if (res.status === 200) {
      dispatch({
        type: GET_INBOX_MESSAGES,
        payload: res.data,
      });
    }
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setLoading(false));
  }
};
