export default {
  en: {
    translations: {
      "Sign In": "Sign In",
      SomethingError: "Something went wrong. Please try after some time.",
      loginInValid: "User with provided username and password doesn't exist.",
      loginEmailNotConfirmed: "Please Confirm your email after registration",
      loginNotApproved: "User not approved",
      loginInActive: "User is deactivated",
      UserAccountExist: "User already exists with the following information.",
      PartnerAccountExist:
        "Partner already exists with the following information.",
      Dashboard: "Dashboard",
      myProfile: "My Profile",
      Partner: "Partner",
      Welcome: "Welcome",
      editProfile: "Edit Profile",
      Active: "Active",
      aboutUs: "About Us",
      Users: "Users",
      partnerDetails: "Partner Details",
      entitySector: "Entity Sector",
      licenseNo: "License No",
      signatoryName: "Signatory Name",
      tradeLicense: "Trade License",
      issuingAuthority: "Issuing Authority",
      issueDate: "Issue Date",
      expiryDate: "Expiry Date",
      contactInfo: "Contact Information",
      Address: "Address",
      poBox: "P.O Box",
      Tel: "Tel",
      Email: "Email",
      requestMou: "Request Agreement",
      No: "No.",
      Name: "Name",
      Designation: "Designation",
      emailID: "Email ID",
      contactNo: "Contact No.",
      Role: "Role",
      Status: "Status",
      manageAgreements: "Manage Agreements",
      Reports: "Reports",
      allAgreements: "All Agreements",
      underProcess: "Under Process",
      asignedAgreements: "Signed Agreements",
      expiringSoon: "Expiring Soon",
      archivedAgreements: "Archived Agreements",
      Notifications: "Notifications ",
      chooseTypeOfCollaboration: "Choose type of collaboration.",
      Connect: "Connect",
      partnershipMou: "Partnership Agreement",
      sponsorshipAgreement: "Sponsorship Agreement",
      outsourcingServiceCenterAgreements:
        "Outsourcing Service Center Agreements",
      FinancialSponsorshipAgreement: "Financial Sponsorship Agreement",
      EventsSponsorshipAgreement: "Events Sponsorship Agreement",
      offersAndDiscountsAgreement: "Offers and Discounts Agreement ",
      generalInquirySystem: "General Inquiry System",
      feeCollectionAgreement: "Fee Collection Agreement ",
      nondisclosureAgreement: "Non-disclosure Agreement",
      academicAndTrainingCentersAgreements:
        "Academic and Training Centers Agreements",
      internationalAgreement: "International Agreement",
      bankOfferAndFacilitiesAgreement: "Bank Offer and facilities Agreement",
      organizingConferencesSeminarsAgreement:
        "Organizing conferences, seminars Agreement",
      organizingAwardAgreement: "Organizing award Agreement",
      mediaAgreement: "Media Agreement",
      collaborationAgreement: "Collaboration Agreement",
      electronicLinkAgreement: "Electronic Link Agreement",
      patnerReviewMessage: "Your review has been succesfully submitted.",
      notificationsAndAlerts: "Notifications and Actions",
      IncorrectPassword: "The current password does not match.",
      Actions: "Actions",
      "Filter by": "Filter by",
      NOTEXISTS: "This link has been either expired or not valid.",
      AlreadyProcessingRequest:
        "A cancellation request is already under process.",
      USER_NOT_ASSIGNED_TO_AGREEMENT: "User is not assigned to agreement.",
      NotApprovedByDED: "DED has not approved.",
      AlreadyAdded: "User already added.",
      AlreadyAssignedToThisUserPlsRmvBfrAssignAgain:
        "User has been already assigned.",
      EmailNotConfirmed: "Email is not verified.",
      USER_NOT_EXISTS: "Email is not registered.",
    },
  },
  ar: {
    translations: {
      "Sign In": "تسجيل الدخول",
      SomethingError: "هناك خطأ ما. يرجى المحاولة بعد مرور بعض الوقت.",
      loginInValid: "مستخدم غير صالح",
      loginEmailNotConfirmed: "Please Confirm your email after registration",
      loginNotApproved: "User not approved",
      loginInActive: "User is deactivated",
      registerUserAccountExist: "المستخدم موجود بالفعل بالمعلومات التالية",
      registerPartnerAccountExist:
        "Partner already exists with the following information.",
      Dashboard: "لوحة القيادة",
      myProfile: "الملف الشخصي",
      Partner: "ماثيو كوشي",
      Welcome: "ترحيب",
      editProfile: "تعديل الملف الشخصي",
      Active: "فعال",
      aboutUs: "نبذة عنا",
      Users: "المستخدمون",
      partnerDetails: "معلومات عن الشريك",
      entitySector: "قطاع الشريك",
      licenseNo: "رقم الترخيص",
      signatoryName: "الممثل المعتمد",
      tradeLicense: "الرخصة التجارية",
      issuingAuthority: "جهة إصدار الترخيص",
      issueDate: "تاريخ الإصدار",
      expiryDate: "تاريخ انتهاء الصلاحية",
      contactInfo: "معلومات الاتصال",
      Address: "اسم الشريك",
      poBox: "صندوق البريد",
      Tel: "صندوق البريد",
      Email: "البريد الإلكتروني",
      requestMou: "طلب اتفاقية شراكة",
      No: "الرقم",
      Name: "الاسم",
      Designation: "المسمى الوظيفي",
      emailID: "البريد الالكتروني",
      contactNo: "	رقم التواصل",
      Role: "Role",
      Status: "الحالة",
      manageAgreements: "إدارة الاتفاقيات",
      Reports: "التقرير ",
      allAgreements: "جميع الاتفاقيات",
      underProcess: "قيد الإجراء ",
      asignedAgreements: "الاتفاقيات الموقعة",
      expiringSoon: "تنتهي قريبا",
      archivedAgreements: "الأرشيف",
      Notifications: " الاشعارات الحديثة ",
      chooseTypeOfCollaboration: "اختر نوع الشراكة",
      Connect: " التواصل",
      partnershipMou: "اتفاقية شراكة",
      sponsorshipAgreement: " اتفاقية الرعاية",
      outsourcingServiceCenterAgreements: "اتفاقية مراكز التعهيد",
      FinancialSponsorshipAgreement: "اتفاقية رعاية مالية ",
      EventsSponsorshipAgreement: " اتفاقية رعاية فعاليات",
      offersAndDiscountsAgreement: "اتفاقية عروض وخصومات",
      generalInquirySystem: "اتفاقية الاستفسار العام ",
      feeCollectionAgreement: "اتفاقية تحصيل الرسوم ",
      nondisclosureAgreement: "اتفاقية عدم الإفصاح وسرية المعلومات ",
      academicAndTrainingCentersAgreements:
        "اتفاقية الجهات التعليمية والمؤسسات التدريبية",
      internationalAgreement: "اتفاقية دولية",
      bankOfferAndFacilitiesAgreement: "اتفاقية عروض وتسهيلات بنكية ",
      organizingConferencesSeminarsAgreement: "اتفاقيات تنظيم مؤتمرات، ندوات",
      organizingAwardAgreement: "اتفاقية تنظيم جائزة ",
      mediaAgreement: "اتفاقيات إعلامية",
      collaborationAgreement: "اتفاقية تعاون ",
      electronicLinkAgreement: "اتفاقية الربط الالكتروني",

      patnerReviewMessage: "تم تقديم رأيك بنجاح.",
      notificationsAndAlerts: "Notifications and Actions",
      IncorrectPassword: "كلمة المرور الحالية غير متطابقة",
      Actions: "Actions",
      "Filter by": "مصنف بواسطة",

      NOTEXISTS: "This link has been either expired or not valid.",
      AlreadyProcessingRequest:
        "A cancellation request is already under process.",
      USER_NOT_ASSIGNED_TO_AGREEMENT: "User is not assigned to agreement.",
      NotApprovedByDED: "DED has not approved.",
      AlreadyAdded: "User already added.",

      AlreadyAssignedToThisUserPlsRmvBfrAssignAgain:
        "User has been already assigned.",

      EmailNotConfirmed: "Email is not verified.",
      USER_NOT_EXISTS: "Email is not registered.",
    },
  },
};
