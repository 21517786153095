import permissions from "../config/permissions";

export default function (roleId) {
  switch (roleId) {
    case 1:
      return permissions.stakeholderAdmin;
    case 2:
      return permissions.partnerAdmin;
    case 3:
      return permissions.partnerUser;
    case 4:
      return permissions.dedUser;

    default:
      return [];
  }
}
