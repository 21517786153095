import {
  GET_ALL_COUNSELS,
  GET_COUNSELS_BY_USER,
  GET_COUNSEL_BY_ID,
  RESET_ALL,
} from "../config/actionTypes";

const initialState = {
  currentCouncel: null,
  allCounsels: null,
  counselsByUser: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_COUNSELS_BY_USER:
      return {
        ...state,
        counselsByUser: action.payload,
      };
    case GET_ALL_COUNSELS:
      return {
        ...state,
        allCounsels: action.payload,
      };
    case GET_COUNSEL_BY_ID:
      return {
        ...state,
        currentCouncel: action.payload,
      };

    case RESET_ALL:
      return initialState;
    default:
      return state;
  }
}
