import {
  CHANGE_LANGUAGE,
  CLEAR_ERRORS,
  SET_MESSAGE,
  SET_LOADING,
  GET_AGREEMENTS_TYPES,
  GET_EMIRATES,
  GET_DEPARTMENTS,
  GET_USERS_BY_DEPT,
  GET_USER_ROLES,
  GET_AGREEMENT_RENEW_TYPES,
  GET_STATS,
  GET_RESOURCE_TYPES,
  GET_AGREEMENT_APPLICATION_TYPES,
  UPLOAD_FILE,
} from "../config/actionTypes";

import api from "../utils/api";
import apiUrls from "../config/apiUrls";
import { isArabic } from "../i18n";
import convertObjectToFormdata from "../utils/objectToFormdata";

export const uploadFile = (data) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const formData = convertObjectToFormdata(data, "Files");
    const res = await api.post(apiUrls.uploadFile, formData);

    if (res.status === 201) {
      dispatch({
        type: UPLOAD_FILE,
      });
      return res.data;
    }
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};

export const getMasterData = (params) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const res = await api.get(apiUrls.getMasterData, { params });

    let type = "";
    if (res.status === 200) {
      if (params.type === "AgreementType")
        type = GET_AGREEMENT_APPLICATION_TYPES;

      dispatch({
        type,
        payload: res.data,
      });
    }
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};

export const getStats = () => async (dispatch) => {
  const res = await api.get(apiUrls.getStats, {
    params: {
      lang: isArabic ? "ar" : "en",
    },
  });

  dispatch({
    type: GET_STATS,
    payload: res.data,
  });
};

export const changeLanguage = (ln) => (dispatch) => {
  dispatch({
    type: CHANGE_LANGUAGE,
    payload: ln,
  });
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};

export const setMessage = (message) => {
  return {
    type: SET_MESSAGE,
    payload: message,
  };
};

export const setLoading = (status) => {
  return {
    type: SET_LOADING,
    payload: status,
  };
};

export const getAgreementsByRole = (params) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const res = await api.get(apiUrls.getAgreementsByRole, { params });

    dispatch({
      type: GET_AGREEMENTS_TYPES,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(setLoading(false));
  }
};

export const getEmirates = (params) => async (dispatch) => {
  try {
    const res = await api.get(apiUrls.getEmirates, { params });

    dispatch({
      type: GET_EMIRATES,
      payload: res.data,
    });
  } catch (error) {}
};

export const getDepartments = (params) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const res = await api.get(apiUrls.getDepartments, { params });

    dispatch({
      type: GET_DEPARTMENTS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const getUsersPerDepartment = (params) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const res = await api.get(apiUrls.getUsersByDept, {
      params: {
        ...params,
        lang: isArabic ? "ar" : "en",
      },
    });

    dispatch({
      type: GET_USERS_BY_DEPT,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const getRolesByUsertype = (params) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const res = await api.get(apiUrls.getUserRoles, { params });

    dispatch({
      type: GET_USER_ROLES,
      payload: res.data,
    });
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};

export const getAgreementRenewables = (params) => async (dispatch) => {
  try {
    const res = await api.get(apiUrls.getAgreementRenewables, { params });

    if (res.status === 200) {
      dispatch({
        type: GET_AGREEMENT_RENEW_TYPES,
        payload: res.data,
      });
    }
  } catch (error) {}
};

export const getResourceTypes = (params) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const res = await api.get(apiUrls.getResourceTypes, { params });

    if (res.status === 200) {
      dispatch({
        type: GET_RESOURCE_TYPES,
        payload: res.data,
      });
    }
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};
