import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import partnerReducer from "./partnerReducer";
import notificationsReducer from "./notificationsReducer";
import generalReducer from "./generalReducer";
import agreementReducer from "./agreementReducer";
import documentReducer from "./documentReducer";
import connectionReducer from "./connectionReducer";
import partnershipReducer from "./partnershipReducer";
import counselReducer from "./counselReducer";
import meetingReducer from "./meetingReducer";

export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  partner: partnerReducer,
  notifications: notificationsReducer,
  general: generalReducer,
  agreement: agreementReducer,
  document: documentReducer,
  connect: connectionReducer,
  partnership: partnershipReducer,
  counsel: counselReducer,
  meeting: meetingReducer,
});
