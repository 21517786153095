export default {
  stakeholderAdmin: [
    { label: "Dashboard", subMenu: null, url: "/dashboard" },
    { label: "Request Agreement", subMenu: null, url: "/request-mou" },
    { label: "Manage Partners", subMenu: null, url: "/manage-partners" },
    {
      label: "Manage Agreements",
      subMenu: [
        { label: "All Agreements", url: "/manage-agreements" },
        { label: "Under Process", url: "/manage-agreements?status=3" },
        { label: "Signed Agreements", url: "/manage-agreements?status=1" },
        {
          label: "Archived Agreements",
          url: "/manage-agreements?archived=true",
        },
      ],
      url: false,
    },

    { label: "Manage Counsels", subMenu: null, url: "/manage-counsels" },
    { label: "My Meetings", subMenu: null, url: "/my-meetings" },
  ],
  partnerAdmin: [
    { label: "Dashboard", subMenu: null, url: "/dashboard" },

    { label: "Request Agreement", subMenu: null, url: "/request-mou" },
    { label: "Request Counsel", subMenu: null, url: "/request-counsel" },
    {
      label: "Manage Agreements",
      subMenu: [
        { label: "All Agreements", url: "/manage-agreements" },
        { label: "Under Process", url: "/manage-agreements?status=3" },
        { label: "Signed Agreements", url: "/manage-agreements?status=1" },
        {
          label: "Archived Agreements",
          url: "/manage-agreements?archived=true",
        },
      ],
      url: null,
    },

    { label: "Manage Counsels", subMenu: null, url: "/manage-counsels" },
    { label: "My Meetings", subMenu: null, url: "/my-meetings" },
  ],

  partnerUser: [
    { label: "Dashboard", subMenu: null, url: "/dashboard" },

    { label: "Request Agreement", subMenu: null, url: "/request-mou" },

    { label: "Request Counsel", subMenu: null, url: "/request-counsel" },
    {
      label: "Manage Agreements",
      subMenu: [
        { label: "All Agreements", url: "/manage-agreements" },
        { label: "Under Process", url: "/manage-agreements?status=3" },
        { label: "Signed Agreements", url: "/manage-agreements?status=1" },
        {
          label: "Archived Agreements",
          url: "/manage-agreements?archived=true",
        },
      ],
      url: null,
    },

    { label: "Manage Counsels", subMenu: null, url: "/manage-counsels" },
    { label: "My Meetings", subMenu: null, url: "/my-meetings" },
  ],

  dedUser: [
    { label: "Dashboard", subMenu: null, url: "/dashboard" },

    { label: "Request Agreement", subMenu: null, url: "/request-mou" },

    { label: "Request Counsel", subMenu: null, url: "/request-counsel" },
    {
      label: "Manage Agreements",
      subMenu: [
        { label: "All Agreements", url: "/manage-agreements" },
        { label: "Under Process", url: "/manage-agreements?status=3" },
        { label: "Signed Agreements", url: "/manage-agreements?status=1" },
        {
          label: "Archived Agreements",
          url: "/manage-agreements?archived=true",
        },
      ],
      url: null,
    },

    { label: "Manage Counsels", subMenu: null, url: "/manage-counsels" },
    { label: "My Meetings", subMenu: null, url: "/my-meetings" },
  ],
};
