import React from "react";

const Footer = () => {
  return (
    <div className="footer text-center shadow">
      © {process.env.REACT_APP_BRAND_NAME}. {new Date().getFullYear()}. All
      rights reserved.
    </div>
  );
};

export default Footer;
