import diff from "node-htmldiff";

import {
  GET_DOCUMENT,
  GET_DOCUMENT_CHANGE_HISTORY,
  GET_DOCUMENT_VERSIONS,
  RESET_ALL,
} from "../config/actionTypes";

const initialState = {
  documentVersions: null,
  current: null,
  otherInfo: null,
  sectionHistories: null,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_DOCUMENT_CHANGE_HISTORY: {
      return {
        ...state,
        sectionHistories: {
          ...state.sectionHistories,
          [action.payload.id]: action.payload.data,
        },
      };
    }
    case GET_DOCUMENT:
      return {
        ...state,
        current: action.payload,
      };
    case GET_DOCUMENT_VERSIONS:
      return {
        ...state,
        documentVersions: action.payload.map((ver) => ({
          ...ver,
          VersionNo: ver.VersionNo.toFixed(1),
        })),
      };

    case RESET_ALL:
      return initialState;

    default:
      return state;
  }
}
