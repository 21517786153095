import React, { useEffect, useState } from "react";
import { User, LogOut, ChevronDown } from "react-feather";
import { useTranslation } from "react-i18next";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";

import adminLogo from "../../assets/images/logo.png";
import avatar from "../../assets/images/avatar.png";

const Header = (props) => {
  const { i18n } = useTranslation();

  const [profileImage, setProfileImage] = useState(avatar);

  useEffect(() => {
    if (props.userDetails?.ProfilePicture)
      setProfileImage(
        process.env.REACT_APP_BASE_URL + props.userDetails.ProfilePicture
      );
    else if (props.userDetails?.CompanyLogo)
      setProfileImage(
        process.env.REACT_APP_BASE_URL + props.userDetails.CompanyLogo
      );
  }, [props.userDetails]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    props.onLanguageChange(lng);
    window.location.reload();
  };

  return (
    <div
      id="pcoded"
      className="pcoded iscollapsed"
      theme-layout="vertical"
      vertical-placement={i18n.language === "ar" ? "right" : "left"}
      vertical-layout="wide"
      pcoded-device-type="desktop"
      vertical-nav-type="expanded"
      vertical-effect="shrink"
      vnavigation-view="view1"
      nav-type="st6"
      fream-type="theme1"
      sidebar-img="false"
      sidebar-img-type="img1"
      layout-type="light"
    >
      {/* <div className="pcoded-overlay-box"></div> */}
      <div className="pcoded-container navbar-wrapper">
        <nav
          className="navbar header-navbar pcoded-header iscollapsed"
          header-theme="theme4"
          pcoded-header-position="fixed"
        >
          <div className="navbar-wrapper d-flex align-items-center">
            <div className="navbar-logo" logo-theme="theme4">
              <a style={{ height: "100%" }}>
                <img
                  className="img-fluid"
                  style={{ maxHeight: "100%" }}
                  src={adminLogo}
                  alt="DED Smart Stakeholders System"
                />
              </a>
            </div>

            <div className="col-auto col-lg navbar-container container-fluid d-inline-block p-0 ml-auto">
              <ul className="nav-right d-block">
                <li className="user-profile header-notification text-nowrap">
                  <div className="dropdown-primary dropdown">
                    <Dropdown>
                      <Dropdown.Toggle
                        as="div"
                        align="down"
                        id="dropdown-basic"
                        className="dropdown-toggle"
                      >
                        <img
                          src={profileImage}
                          className="img-radius"
                          alt="User-Profile-Image"
                        />
                        {props.userDetails && (
                          <span className="d-none d-sm-inline-block">
                            {props.userDetails?.Email} (
                            {props.userDetails?.RoleName})
                          </span>
                        )}
                        <ChevronDown size={14} />
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        as="ul"
                        className="show-notification profile-notification dropdown-menu "
                      >
                        <Dropdown.Item as="li">
                          <User size={14} /> <Link to="/profile">Profile</Link>
                        </Dropdown.Item>
                        <Dropdown.Item as="li" onClick={props.onLogout}>
                          <LogOut size={14} /> Logout
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </li>

                <li className="user-profile header-notification text-nowrap multilng">
                  <strong
                    className={i18n.language !== "ar" ? "active" : ""}
                    onClick={() => changeLanguage("en")}
                  >
                    ENGLISH
                  </strong>
                  |
                  <strong
                    className={i18n.language === "ar" ? "active" : ""}
                    onClick={() => changeLanguage("ar")}
                  >
                    عربي
                  </strong>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
