import React, { useState, useEffect } from "react";
import Toast from "react-bootstrap/Toast";
import { useTranslation } from "react-i18next";

import logo from "../assets/images/favicon.png";

const Notification = (props) => {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (props.message) {
      setShow(true);
    }
  }, [props.message]);

  useEffect(() => {
    return () => {
      props.onHide();
    };
  }, []);

  const handleClose = () => {
    setShow(false);
    props.onHide();
  };
  return (
    <Toast
      show={show}
      autohide={true}
      onClose={handleClose}
      style={{
        position: "fixed",
        right: "50%",
        transform: "translateX(50%)",
        top: 80,
        zIndex: 999999999,
        minWidth: 400,
      }}
    >
      <Toast.Header>
        <img
          style={{ height: 20, width: 20 }}
          src={logo}
          className="rounded mr-2"
          alt=""
        />
        <strong className="mr-auto">Message</strong>
        <small>Few seconds ago</small>
      </Toast.Header>
      <Toast.Body>{t(props.message)}</Toast.Body>
    </Toast>
  );
};

export default Notification;
