import {
  GET_NOTIFICATIONS,
  SET_NOTIFICATION_LOADING,
  GET_FILTERED_NOTIFICATIONS,
  RESET_ALL,
} from "../config/actionTypes";

const initialState = {
  data: [],
  filtered: [],
  isLoading: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_NOTIFICATIONS:
      return {
        ...state,
        data: payload,
      };
    case GET_FILTERED_NOTIFICATIONS:
      return {
        ...state,
        filtered: payload,
      };

    case SET_NOTIFICATION_LOADING:
      return {
        ...state,
        isLoading: payload,
      };

    case RESET_ALL:
      return initialState;

    default:
      return state;
  }
}
