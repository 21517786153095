import React from "react";
import AsyncSelect from "react-select/async";
import { useTranslation } from "react-i18next";

const AsyncAutocomplete = (props) => {
  const { i18n } = useTranslation();
  const isAr = i18n.language === "ar";

  return (
    <>
      <AsyncSelect
        {...props}
        isRtl={isAr}
        cacheOptions
        defaultOptions
        className={props.touched && props.error ? "is-invalid" : ""}
        styles={{
          control: (styles) => ({
            ...styles,
            borderRadius: "2px",
            borderColor:
              !!props.error && props.touched ? "var(--danger)" : "#eee",
          }),
        }}
      />
      {!!props.error && props.touched && (
        <div className="invalid-feedback">{props.error}</div>
      )}
    </>
  );
};

export default AsyncAutocomplete;