export default {
  login: "User/Login",
  forgetPassword: "/User/ForgetPassword",
  resetPassword: "/User/ResetPassword",
  validateResetPasswordLink: "/User/ResetPasswordConfirm",
  getToken: "/token",
  getStats: "/Agreement/GetStatCards",

  partnerDropdowns: "/Partner/RegisterDropDownValues",
  getOtherEmirates: "/Partner/GetOtherEmirates",
  partnerRegister: "/Partner/Register",
  partnerReregister: "/Partner/re_request",
  checkPartnerExists: "/Partner/IsPartnerExists",
  checkUserExists: "/User/IsUserExists",
  getPartnerReview: "/partner/get",
  updateReview: "/partner/review",
  getPartnerUsers: "/Partner/Users",
  getPartners: "/Partner/getAll",
  confirmEmail: "/User/ConfirmEmail",

  getEmirates: "/Partner/GetEmirates",
  getAgreementsByRole: "/Agreement/GetTypes",
  addDiscountOffer: "/Agreement/AddDiscountOffer",
  getDiscountOffer: "/Agreement/GetDiscountOfferByCode",
  editDiscountOffer: "/Agreement/EditDiscountOffer",
  submitAgreement: "/Agreement/SubmitAgreement",
  addOutlets: "/Agreement/AssignOrUpdateOutlet",
  removeOutlets: "/Agreement/RemoveOutlet",

  rejectAgreement: "/Agreement/Reject",
  approveAgreement: "/Agreement/Approve",
  getAgreementReviewHistory: "/Agreement/GetAgreementReviewHistory",
  generateAgreement: "/Agreement/GenerateAgreement",
  regenearteAgreement: "/Agreement/ReGenerateAgreement",

  getPartnerAgreements: "/Agreement/GetPartnerAgreement",
  getAllAgreements: "/Agreement/GetAllAgreements",
  getProcessDurations: "/Agreement/GetProcessDuration",

  getDocumentVersions: "/Agreement/GetAgreementDocumentsHistory",
  getDocument: "/Agreement/GetAgreementDocument",
  assignDocumentForEdit: "/Agreement/AssignDocEdit",
  unAssignUserFromDocument: "/Agreement/RemoveDocEdit",
  saveDocument: "/Agreement/SaveDocument",
  getDocumentReviewDetails: "/Agreement/GetReviewDetails",

  getMyAgreementReviewDetails: "/Agreement/GetMyReviewDetails",
  commentAgreement: "/Agreement/SubmitReview",
  commentAgreementWithCustomTemplate: "/Agreement/CustomTemplateSubmitReview",
  uploadEditedAgreementByStakeholder: "/Agreement/CustomTemplateSave",
  partnerAgreementApproval: "/Agreement/PartnerApproval",
  stakeholderAgreementApproval: "/Agreement/StakeHolderApproval",
  assignWithinDept: "/Agreement/AssignToOtherDepUser",

  getAgreementApprovalDetails: "/Agreement/GetAgreementApprovalDetails",

  getNotifications: "/Notification/GetAllUserNotification",
  getFilteredNotifications: "/Notification/GetAllUserNotificationWithFilter",
  updateNotificationView: "/Notification/UpdateAsViewed",

  getDepartments: "/Department/GetAll",
  getUsersByDept: "/User/GetUsersByDepartment",

  verifySigningToken: "/Notification/VerifyToken",
  partnerAgreementSign: "/Agreement/PartnerSigining",
  stakeholderAgreementSign: "/Agreement/StakeholderSigining",
  dedAgreementSign: "/Agreement/DEDSigning",

  requestAgreementCancellation: "/AgreementCancellation/RequestingCancellation",
  getAllCancellationRequest: "/AgreementCancellation/GetRequestSummary",
  getCancellationRequest: "/AgreementCancellation/GetARequestById",
  legalCancellationApproval: "/AgreementCancellation/LegalApproval",
  stakeholderCancellationApproval: "/AgreementCancellation/StakeholderApproval",
  stakeholderCancellation: "/AgreementCancellation/StakeHolderCancellation",

  requestAgreementRenewal: "/Agreement/Renew",
  requestAgreementAmend: "/Agreement/Supplementary",

  updatePartnerProfile: "/Partner/edit",
  updateUserProfile: "/User/UpdateProfile",
  getMyProfile: "/User/GetMyProfile",
  changePassword: "/User/ChangePassword",
  getMyDepartmentInfo: "/User/GetMyDEDInfo",

  getUserRoles: "/Role/GetRolesByType",

  getConnectUsers: "/Connect/GetConnectUsers",
  sendConnect: "/Connect/NewMessage",
  getSendMessages: "/Connect/GetSentItemsMessages",
  getInbox: "/Connect/GetInboxMessages",

  addPartner: "/Partner/AddPartner",
  invitePartner: "/Partner/Invite",
  addPartnerUser: "/Partner/AddUser",
  getPartnerClassifications: "/Partner/GetClassifications",

  getAgreementRenewables: "/Agreement/GetAgreementRenewals",
  getAgreementLanguages: "/Agreement/GetAgreementLanguages",

  getPartnershipDetails: "/Agreement/GetPartnershipDetails",
  savePartnershipDetails: "/Agreement/AddEditPartnership",
  getSelectionCriteria: "/Agreement/GetAllSelectionCriteria",
  saveSelectionCriteria: "/Agreement/SaveSelectionCriteria",
  assignContact: "/Agreement/AssignContact",
  addNewContact: "/Agreement/AddNewContact",
  removeContact: "/Agreement/RemoveContact",
  makeDefaultContact: "/Agreement/MarkAsDefaultContact",

  getAgreementFlow: "Agreement/GetAgreementFlow",

  getMouDetails: "/Partnership/GetMOUDetails",
  saveMouResourcePlanning: "/Partnership/AddEditResourcePlanning",

  getLikelihoods: "/Partnership/GetLikelihoods",
  getSeverities: "/Partnership/GetSeverities",
  getRiskTypes: "/Partnership/GetRiskTypes",

  addRiskAssessment: "/Partnership/AddEditRiskAssessemnt",
  removeRiskAssessment: "/Partnership/DeleteRiskAssessemnt",
  addPerformanceMeasure: "/Partnership/AddEditPerformanceMeasurement",
  removePerformanceMeasure: "/Partnership/DeletePerformanceMeasurement",

  getExitStratergies: "/Partnership/GetExitStrategies",
  saveExitStratergies: "/Partnership/UpdateExitStrategies",

  getMatrices: "/Partnership/GetStakeholderMatrices",
  submitMapping: "/Partnership/SubmitMapping",

  savePartnerObligation: "/Partnership/UpdatePartnerObligation",
  partnerAprroval: "/Partnership/PartnerApproval",

  getPartnershipApprovalDetails: "/Partnership/GetAgreementApprovalDetails",
  getAgreementInfo: "/Agreement/GetInfo",
  getOwners: "/Partnership/GetOwners",

  savePartnershipAdditional: "/Partnership/AddEditAdditonalInfo",
  getResourceTypes: "/AdditionalResource/Get",
  assignResource: "/AdditionalResource/AssignToAgreement",
  removeResource: "/AdditionalResource/RemoveFromAgreement",

  addSecondaryParty: "/SecondaryContact/AddNewContact",
  assignSecondaryParty: "/SecondaryContact/AssignContact",
  removeSecondaryParty: "/SecondaryContact/RemoveContact",

  archiveAgreement: "/Agreement/UpdateAsArchived",
  unArchiveAgreement: "/Agreement/RemoveFromArchived",

  onholdAgreement: "/Agreement/MakeOnHold",
  releaseHoldAgreement: "Agreement/ReleaseHold",

  commentOnSection: "/CollaborationTemplate/AddComment",
  getCommentsOnSection: "/CollaborationTemplate/GetComments",
  updateMentionView: "/Agreement/UpdateMetioned",
  getDocumnentSectionChangeHistory: "/CollaborationTemplate/GetPanelHistory",

  getAgreementActivities: "/Agreement/Activities",

  getMasterData: "/MasterInfo/GetActiveInfoByType",

  requestCounsel: "/Councel/NewRequest",
  reviewCounsel: "/Councel/Review",
  replyCounsel: "/Councel/ReplyBack",
  getCounselById: "/Councel/GetRequestById",
  getCounselsForUser: "/Councel/GetRequestsByUserId",
  getAllCounsels: "/Councel/GetAllRequests",

  addMeeting: "/MeetingEvent/Add",
  getPointOfContacts: "/Agreement/GetPointOfContacts",
  getAllAgreementUsers: "/Agreement/GetPOCWithSecondaryParties",
  getReleventDeptUsers: "/Agreement/GetRelevantDepartmentUsers",
  updateMeetingMinutes: "/MeetingEvent/UpdateMinutes",
  getMeetingsForAgreement: "/MeetingEvent/Get",
  getMeetingsByAgreementForUser: "/MeetingEvent/GetByUserForAgreement",
  getMeetingByUser: "/MeetingEvent/GetByUser",
  getMeetingById: "/MeetingEvent/GetByEventID",
  updateUserResponseForMeeting: "/MeetingEvent/UserResponse",

  uploadFile: "/UploadFile/Add",
};
