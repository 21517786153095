import isEmpty from "is-empty";
import * as queryString from "query-string";

import setAuthInHeaders from "../utils/setAuthToken";
import { setUser as setAuthInStorage, removeUser } from "../utils/storage";
import api from "../utils/api";
import {
  GET_ERRORS,
  SET_CURRENT_USER,
  RESET_ALL,
  GET_USER_PROFILE,
  USER_LOGOUT,
  CHANGE_PASSWORD,
  GET_MY_DEPT_INFO,
  FORGET_PASSWORD,
  VALIDATE_RESET_LINK,
  RESET_PASSWORD,
} from "../config/actionTypes";
import apiUrls from "../config/apiUrls";
import { setLoading, setMessage } from "./generalActions";
import { isArabic } from "../i18n";

export const loginUser = (userData) => async (dispatch) => {
  try {
    const res = await api.post(
      apiUrls.getToken,
      queryString.stringify({
        grant_type: "password",
        username: userData.Email,
        password: userData.Password,
      }),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );

    setAuthInStorage(res.data);
    setAuthInHeaders(res.data);

    dispatch(setCurrentUser(res.data));
  } catch (err) {
    let loginError = "SomethingError";

    if (!isEmpty(err.response.data) && err.response.status < 500) {
      loginError = "login" + err.response.data.error;
    }

    dispatch({
      type: GET_ERRORS,
      payload: { loginError },
    });
  }
};

export const setCurrentUser = (data) => {
  return {
    type: SET_CURRENT_USER,
    payload: data,
  };
};

export const logoutUser = () => (dispatch) => {
  console.log("Logging out");
  // Remove token from local storage
  removeUser();
  // Remove auth header for future requests
  setAuthInHeaders(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));

  // Clear entire store
  dispatch({ type: RESET_ALL });
  dispatch({ type: USER_LOGOUT });
};

export const updateUserProfile = (data, history) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const formData = new FormData();

    formData.append("FirstName", data.FirstName);
    formData.append("LastName", data.LastName);
    formData.append("Mobile", data.Mobile);
    if (data.ProfilePic) formData.append("ProfilePic", data.ProfilePic);

    const res = await api.post(apiUrls.updateUserProfile, formData);

    if (res.status === 200) {
      dispatch(setMessage("Successfully updated your profile."));

      setTimeout(() => {
        history.push("/profile");
      }, 2000);
    }
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};

export const getUserProfile = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const res = await api.get(
      `${apiUrls.getMyProfile}?langCode=${isArabic ? "ar" : "en"}`
    );

    if (res.status === 200) {
      dispatch({
        type: GET_USER_PROFILE,
        payload: res.data,
      });
    }
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};

export const changePassword = (data) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const res = await api.post(apiUrls.changePassword, data);

    if (res.status === 200) {
      dispatch(setMessage("Successfully Changed your password."));
      dispatch({ type: CHANGE_PASSWORD });

      setTimeout(() => {
        dispatch(logoutUser());
      }, 1000);

      return true;
    }
  } catch (error) {
    if (error.response.status === 400)
      dispatch({
        type: GET_ERRORS,
        payload: {
          changePassword: error.response.data?.Message || "SomethingError",
        },
      });
  } finally {
    dispatch(setLoading(false));
  }
};

export const getMyDeptInfo = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const res = await api.get(apiUrls.getMyDepartmentInfo);

    if (res.status === 200) {
      dispatch({
        type: GET_MY_DEPT_INFO,
        payload: res.data,
      });
    }
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};

export const forgotPassword = (data) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await api.post(apiUrls.forgetPassword, data);

    if (res.status === 200) {
      dispatch({
        type: FORGET_PASSWORD,
      });

      return true;
    }
  } catch (err) {
    let forgotError = "SomethingError";

    if (!isEmpty(err.response.data) && err.response.status < 500) {
      forgotError = err.response.data.Message;
    }

    dispatch({
      type: GET_ERRORS,
      payload: { forgotError },
    });
  } finally {
    dispatch(setLoading(false));
  }
};

export const validateResetLink = (id, history) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await api.get(apiUrls.validateResetPasswordLink + id);
    if (res.status === 200) {
      dispatch({
        type: VALIDATE_RESET_LINK,
        payload: res.data,
      });
    }
  } catch (error) {
    history.push("/page-not-found");
  } finally {
    dispatch(setLoading(false));
  }
};

export const resetPassword = (data) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const res = await api.post(apiUrls.resetPassword, data);
    if (res.status === 200) {
      dispatch({
        type: RESET_PASSWORD,
      });

      return true;
    }
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};
