import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  ProSidebar,
  SidebarHeader,
  SidebarContent,
  Menu,
  MenuItem,
  SubMenu,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import {
  Home,
  User,
  FileText,
  Tv,
  Edit,
  Globe,
  Mail,
  Users,
  BookOpen,
  Grid,
  Calendar,
  List,
} from "react-feather";

import getPermissions from "../../utils/getUserPermissions";

const Sidebar = (props) => {
  const { i18n, t } = useTranslation();

  const getMenuicons = (label) => {
    switch (label) {
      case "Dashboard":
        return <Home size={16} />;
      case "Request Agreement":
        return <Edit size={16} />;
      case "Manage Agreements":
        return <FileText size={16} />;
      case "Reports":
        return <Tv size={16} />;

      case "My Profile":
        return <User size={16} />;

      case "Partner Profile":
        return <Globe size={16} />;

      case "DED Profile":
        return <Globe size={16} />;

      case "Manage Partners":
        return <Users size={16} />;

      case "Connect":
        return <Mail size={16} />;

      case "Request Counsel":
        return <BookOpen size={16} />;

      case "Manage Counsels":
        return <List size={16} />;

      case "My Meetings":
        return <Calendar size={16} />;
      default:
        return <Grid size={16} />;
    }
  };

  const renderMenu = (menu, i) => {
    if (menu.url) {
      return (
        <MenuItem
          key={`menu-${i}`}
          className={props.currentPath === menu.url ? "active" : ""}
          icon={getMenuicons(menu.label)}
        >
          {menu.url && <Link to={menu.url} />}
          {t(menu.label)}
        </MenuItem>
      );
    } else
      return (
        <SubMenu
          key={`menu-${i}`}
          title={t(menu.label)}
          icon={getMenuicons(menu.label)}
        >
          {menu.subMenu.map((submenu, i) => (
            <MenuItem
              key={`submen-${i}`}
              className={props.currentPath === submenu.url ? "active" : ""}
            >
              <Link to={submenu.url} />
              {t(submenu.label)}
            </MenuItem>
          ))}
        </SubMenu>
      );
  };

  const userPermission = getPermissions(props.user?.RoleId);

  return (
    <ProSidebar rtl={i18n.language === "ar"}>
      <SidebarHeader style={{ height: 70 }}></SidebarHeader>
      <SidebarContent>
        <Menu iconShape="square">
          {userPermission.map((menu, i) => renderMenu(menu, i))}
        </Menu>
      </SidebarContent>
    </ProSidebar>
  );
};

export default Sidebar;
