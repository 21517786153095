import {
  GET_PARTNER_DROPDOWNS,
  GET_PARTNER_REVIEW,
  GET_PARTNER_USERS,
  CLEAR_PARTNER,
  CONFIRM_EMAIL,
  REGISTER_PARTNER,
  GET_PARTNERS,
  RESET_ALL,
  GET_PARTNER_CLASSIFICATION,
  GET_OTHER_EMIRATES,
} from "../config/actionTypes";

const initialState = {
  reviewDetails: null,
  dropDowns: null,
  users: null,
  isEmailConfirmed: false,
  isRegistrationSuccess: false,
  approvedPartners: null,
  partnerTypes: null,
  otherEmirates: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_OTHER_EMIRATES:
      return {
        ...state,
        otherEmirates: payload,
      };
    case GET_PARTNER_CLASSIFICATION:
      return {
        ...state,
        partnerTypes: payload,
      };
    case GET_PARTNERS:
      return {
        ...state,
        approvedPartners: payload,
      };
    case GET_PARTNER_DROPDOWNS:
      return {
        ...state,
        dropDowns: payload,
      };

    case REGISTER_PARTNER:
      return {
        ...state,
        isRegistrationSuccess: true,
      };
    case GET_PARTNER_REVIEW:
      return {
        ...state,
        reviewDetails: payload,
      };
    case GET_PARTNER_USERS:
      return {
        ...state,
        users: payload,
      };

    case CONFIRM_EMAIL:
      return {
        ...state,
        isEmailConfirmed: true,
      };
    case CLEAR_PARTNER:
      return {
        ...state,
        reviewDetails: null,
      };

    case RESET_ALL:
      return initialState;

    default:
      return state;
  }
}
