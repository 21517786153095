import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import resources from "./constants/multilingual";

i18n.use(LanguageDetector).init({
  // we init with resources
  resources,
  fallbackLng: "en",
  debug: false,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    formatSeparator: ",",
  },

  react: {
    wait: true,
  },
});

export default i18n;

export const isArabic = i18n.language === "ar";
