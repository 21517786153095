import React, { useState } from "react";
import { useField, Field, ErrorMessage } from "formik";
import { InputGroup, Form } from "react-bootstrap";

const TextField = ({ isArabic, ...props }) => {
  const [field, meta, helpers] = useField(props);

  return (
    <>
      <Field
        {...props}
        className={`form-control ${isArabic ? "ar-text" : ""} ${
          meta.touched && meta.error ? "is-invalid" : ""
        }`}
      />
      <ErrorMessage
        name={props.name}
        component="div"
        className="invalid-feedback"
      />
    </>
  );
};

export default TextField;

export const TextFieldWithPostText = ({ postText, ...props }) => {
  const [field, meta, helpers] = useField(props.name);
  return (
    <InputGroup>
      <Field
        {...props}
        className={`form-control ${
          meta.touched && meta.error ? "is-invalid" : ""
        }`}
      />

      <InputGroup.Append>
        <InputGroup.Text>{postText}</InputGroup.Text>
      </InputGroup.Append>
      <ErrorMessage
        name={props.name}
        component="div"
        className="invalid-feedback"
      />
    </InputGroup>
  );
};

export const TextFieldWithApiValidation = ({ validation, ...props }) => {
  const [isValid, setValid] = useState(false);
  const [field, meta, helpers] = useField(props.name);

  const handleChange = async (e) => {
    let res = null;
    helpers.setValue(e.target.value);

    if (e.target.value) res = await validation(e.target.value);

    if (res) {
      helpers.setError("Email has been already registered.");
    }
    setValid(!res);
  };

  return (
    <>
      <Form.Control
        {...props}
        value={field.value}
        onChange={handleChange}
        isInvalid={meta.error}
        isValid={isValid}
      />
      <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
    </>
  );
};
